import * as React from 'react';
import { NextPage } from 'next';

import isServer from 'src/common/isServer';
import { AsyncReducersMap } from 'src/global/store';

interface WithReducerProps {
  asyncReducerKeys?: string[];
}

export function withReducer(asyncReducersMap: AsyncReducersMap) {
  return function withReducerHOC(PageComponent: NextPage) {
    class WithReducer extends React.Component<WithReducerProps> {
      static getInitialProps = async (ctx: any) => {
        const store = ctx.reduxStore;
        store.injectReducers(asyncReducersMap);
        const asyncReducerKeys = Object.keys(asyncReducersMap);

        return PageComponent.getInitialProps
          ? {
              ...(await PageComponent.getInitialProps(ctx)),
              asyncReducerKeys,
            }
          : { asyncReducerKeys };
      };

      constructor(props = {}) {
        super(props);
        if (!isServer) {
          // injectReducers here to replace the asyncReducerPlaceholdersMap
          // with the actual asyncReducersMap,
          // this is only necessary when we launch a page with async reducers,
          // in the subsequential client side navigation,
          // async reducers are injected by the above getInitialProps call.
          const store = window.__NEXT_REDUX_STORE__;
          store.injectReducers(asyncReducersMap);
        }
      }

      render() {
        const { asyncReducerKeys: _, ...props } = this.props;
        return <PageComponent {...props} />;
      }
    }

    return WithReducer;
  };
}
