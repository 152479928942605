import { createSelector } from 'reselect';

import { State } from 'src/global/store';

const webCrawlerUserAgentsRegExp = new RegExp(
  'googlebot|baiduspider|twitterbot|facebookexternalhit|rogerbot|linkedinbot|embedly|quora link preview|showyoubot|outbrain|pinterest|slackbot|vkShare|W3C_Validator',
  'i'
);

const mobileOrTabletDeviceUserAgentsRegExp = new RegExp(
  // ref: https://stackoverflow.com/questions/11381673/detecting-a-mobile-browser
  /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/,
  'i'
);

const getServer = (state: State) => state.server;

export const getUserAgent = (state: State) => getServer(state).userAgent;

export const getIsWebCrawler = createSelector(getUserAgent, (userAgent) =>
  webCrawlerUserAgentsRegExp.test(userAgent)
);
export const getIsMobileOrTabletDevice = createSelector(
  getUserAgent,
  (userAgent) => mobileOrTabletDeviceUserAgentsRegExp.test(userAgent)
);
