import * as React from 'react';
import { Loading, Slider } from 'glints-aries';

import * as S from './TwoCardsSlider.sc';

const createSlideOfTwoCards = (slides: React.ReactNodeArray) => {
  const sliderItems = [];
  for (let i = 0; i < slides.length; i += 2) {
    sliderItems.push(
      <Slider.Item key={i}>
        <S.Item>{slides[i]}</S.Item>
        {i + 1 < slides.length && <S.Item>{slides[i + 1]}</S.Item>}
      </Slider.Item>
    );
  }

  return sliderItems;
};

const TwoCardsSlider = ({ slides }: { slides: React.ReactNodeArray }) => (
  <S.Container>
    {slides.length ? (
      <Slider key="loaded">{createSlideOfTwoCards(slides)}</Slider>
    ) : (
      <Slider key="loading">
        <Slider.Item>
          <S.LoadingItem>
            <Loading />
          </S.LoadingItem>
        </Slider.Item>
      </Slider>
    )}
  </S.Container>
);

export default React.memo(TwoCardsSlider);
