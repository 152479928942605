import { PrimaryColor, ScreenSize } from 'glints-aries';
import styled from 'styled-components';

interface RootProps {
  textAlignOnDesktop: 'center' | 'left';
}

export const Root = styled.div<RootProps>`
  @media (min-width: ${ScreenSize.tablet}px) {
    text-align: ${({ textAlignOnDesktop }) => textAlignOnDesktop};
  }
`;

export const Title = styled.div`
  font-size: 28px;
  font-weight: bold;
  line-height: 1.25;
  text-transform: uppercase;
  @media (min-width: ${ScreenSize.tablet}px) {
    font-size: 32px;
    line-height: 1.02;
  }
`;

export const Divider = styled.div`
  display: inline-block;
  width: 60px;
  height: 4px;
  background-color: ${PrimaryColor.glintsyellow};
  margin: 20px 0;

  @media (min-width: ${ScreenSize.tablet}px) {
    margin: 30px 0;
  }
`;

export const Subtitle = styled.div`
  font-size: 18px;
  line-height: normal;

  @media (min-width: ${ScreenSize.tablet}px) {
    font-size: 20px;
  }
`;
