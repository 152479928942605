import { withReducer } from 'nextDir/enhancers/page/withReducer';
import { compose } from 'recompose';

import LandingPsychFlat from 'src/modules/Landing/LandingPsychFlat/LandingPsychFlat';
import { landingPsychFlatReducer } from 'src/modules/Landing/Reducer';
import { ExpertClassesReducer } from 'src/reducers/expertClasses';

import withApollo from '../next/enhancers/page/withApollo';

export default compose(
  withApollo,
  withReducer({
    expertClasses: ExpertClassesReducer,
    landingPsychFlat: landingPsychFlatReducer,
  })
)(LandingPsychFlat);
