import { PrimaryColor, ScreenSize, Typography } from 'glints-aries';
import styled from 'styled-components';

import GlintsContainer from 'src/components/GlintsContainer';

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${PrimaryColor.glintsyellow};
  padding: 50px 20px;

  @media screen and (max-width: ${ScreenSize.tablet - 1}px) {
    padding: 40px 15px;
  }
`;

export const SectionHeader = styled(Typography.Title).attrs({
  tag: 'h5',
})`
  text-align: center;
  font-weight: 500;
  margin-bottom: 30px;

  @media screen and (max-width: ${ScreenSize.tablet - 1}px) {
    font-size: 18px;
    margin-bottom: 20px;
  }
`;

export const SectionSearchContainer = styled(GlintsContainer)`
  width: 100%;
`;
