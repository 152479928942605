import { Greyscale, ScreenSize, SecondaryColor } from 'glints-aries';
import styled from 'styled-components';

export const Root = styled.div``;

export const Header = styled.div`
  margin-bottom: 40px;
  @media (min-width: ${ScreenSize.tablet}px) {
    margin-bottom: 60px;
    text-align: center;
  }
  @media (max-width: ${ScreenSize.mobileL}px) {
    margin-bottom: 40px;
  }
`;

export const Title = styled.div`
  font-size: 32px;
  font-weight: 900;
  line-height: 68px;
  margin-bottom: 16px;
  text-transform: uppercase;
  @media (max-width: ${ScreenSize.tablet}px) {
    line-height: 48px;
    text-align: center;
  }

  @media (max-width: ${ScreenSize.mobileL}px) {
    font-size: 28px;
    line-height: 42px;
    text-align: center;
  }
`;

export const Subtitle = styled.div`
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: normal;
  max-width: 430px;
  margin: 0 auto;
  @media (max-width: ${ScreenSize.tablet}px) {
    max-width: 720px;
    text-align: center;
  }

  @media (max-width: ${ScreenSize.mobileL}px) {
    max-width: 591px;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }
`;

export const Cards = styled.div`
  margin-bottom: 40px;
  a {
    color: ${Greyscale.black};
    display: block;
  }

  @media (min-width: ${ScreenSize.tablet}px) {
    margin: 0 auto 60px;
    max-width: 1000px;
    display: flex;
    column-gap: 32px;
    a {
      flex-grow: 1;
      width: calc((90%) / 4);
      &:not(:last-child) {
        margin-bottom: 0;
      }
    }
  }

  @media (max-width: ${ScreenSize.tablet}px) {
    display: flex;
    column-gap: 32px;
    a {
      max-width: 344px;
      width: 100%;
      flex-grow: 1;
    }
  }

  @media (max-width: ${ScreenSize.mobileL}px) {
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    a {
      max-width: 100%;
      flex-grow: 1;
    }
  }
`;

export const Card = styled.div`
  line-height: 1.4285em;
  text-transform: uppercase;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  background-color: ${Greyscale.white};
  display: flex;
  align-items: center;
  height: 144px;
  padding: 32px;
  @media (min-width: ${ScreenSize.tablet}px) {
    padding: 32px;
    text-align: center;
    height: 164px;
    flex-direction: row;
    justify-content: flex-start;

    transition: all 0.3s;
    svg {
      transition: fill 0.3s;
    }

    &:hover {
      color: ${Greyscale.white};
      background-color: ${SecondaryColor.actionblue};
      svg {
        fill: ${Greyscale.white};
      }
    }
  }

  @media (max-width: ${ScreenSize.mobileM}px) {
    padding: 16px;
  }
`;

export const CardImage = styled.div`
  font-size: 100px;
  @media (max-width: ${ScreenSize.tablet}px) {
    font-size: 80px;
  }
`;

export const CardText = styled.div`
  display: flex;
  margin-left: 16px;
  flex-direction: column;
  align-items: flex-start;

  @media (min-width: ${ScreenSize.desktopM}px) {
    flex-direction: row;
    align-items: center;
  }
`;

export const CardTitle = styled.div`
  font-weight: 700;
  font-size: 32px;
  line-height: 54px;

  @media (max-width: ${ScreenSize.mobileL}px) {
    font-size: 28px;
    line-height: 42px;
  }
`;

export const CardSubtitle = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  margin-left: 0;
  text-align: start;
  @media (min-width: ${ScreenSize.desktopM}px) {
    margin-left: 8px;
  }
`;

export const Image = styled.div`
  margin: 0 -10px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  img {
    margin-left: 20%;
    width: 150%;
    align-self: flex-start;
  }

  @media (min-width: ${ScreenSize.tablet}px) {
    margin: 0 auto;
    max-width: 808px;
    img {
      margin-left: 0;
      width: 100%;
    }
  }
`;
