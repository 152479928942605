import React from 'react';
import classNames from 'classnames';

/**
 * 1. Concatenates a string to the child's class which is then used in
 *      Google Tag Manager to track certain metrics about the child element
 * 2. Provide the class name to a function children to extend the usage
 *
 * the main purpose of this component is making sure each gtm class is unique
 */
export type Tag = string | string[];

export interface ChildrenProps {
  className?: string;
}

export type FunctionalChildren = (tag: Tag) => React.ReactElement<any>;

interface Props {
  tag: Tag;
  children: FunctionalChildren | React.ReactElement<ChildrenProps>;
  className?: string;
}

export const GoogleTagManager = ({ tag, children, ...otherProps }: Props) => {
  if (!React.isValidElement(children)) {
    return (children as FunctionalChildren)(tag);
  }
  if (Array.isArray(tag)) {
    throw new Error('only accept array type in functional children.');
  }

  const childrenProps = children.props;
  const className = classNames(childrenProps.className, tag);
  return React.cloneElement(children, {
    ...otherProps,
    ...childrenProps,
    className,
  });
};
