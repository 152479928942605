import React from 'react';

import { CountryCodes } from 'src/common/enums';
import { useCountry } from 'src/common/hooks';

export const SupersonicBanner = () => {
  const country = useCountry();
  if (country !== CountryCodes.ID) return null;
  return (
    <a href="https://form.typeform.com/to/jqoPHqFY" target="_top">
      <picture>
        <source
          srcSet="/images/banners/supersonic/supersonic-768.png"
          media="(min-width: 768px) and (max-width: 1023px)"
        />
        <source
          srcSet="/images/banners/supersonic/supersonic-1024.png"
          media="(min-width: 1024px) and (max-width: 1439px)"
        />
        <source
          srcSet="/images/banners/supersonic/supersonic-1440.png"
          media="(min-width: 1440px)"
        />
        <img
          src="/images/banners/supersonic/supersonic-360.png"
          style={{ marginBottom: '-7px', width: '100%' }}
        />
      </picture>
    </a>
  );
};
