import * as React from 'react';
import dynamic from 'next/dynamic';
import { FormattedMessage } from 'react-intl';

import { EBreakpoints, Media } from 'src/media';

import * as Styled from './SectionOmniSearch.sc';

const SearchFieldContainerMobile = dynamic(
  () =>
    import(
      'src/modules/Opportunities/pages/Explore/Components/SearchSection/SearchSectionMobile/MainSearchField'
    ).then((module) => module.MainSearchField),
  { ssr: false }
);
const SearchFieldContainerDesktop = dynamic(
  () =>
    import(
      'src/modules/Opportunities/pages/Explore/Components/SearchSection/SearchSectionDesktop/SearchContainer'
    ).then((module) => module.SearchContainer),
  { ssr: false }
);

export const SectionOmniSearch: React.FC<
  React.PropsWithChildren<Record<string, unknown>>
> = () => (
  <Styled.Section>
    <FormattedMessage
      id="homepage.omnisearch.title"
      defaultMessage="Explore <b>15000+</b> of new jobs posted monthly!"
      tagName={Styled.SectionHeader}
      values={{
        b: (...chunks: React.ReactNode[]) => <b>{chunks}</b>,
      }}
    />
    <Styled.SectionSearchContainer>
      <Media lessThan={EBreakpoints.desktopS}>
        <SearchFieldContainerMobile />
      </Media>
      <Media greaterThanOrEqual={EBreakpoints.desktopS}>
        <SearchFieldContainerDesktop isHomepage={true} />
      </Media>
    </Styled.SectionSearchContainer>
  </Styled.Section>
);
