import * as React from 'react';
import { NextPage } from 'next';

import { getDisplayName } from '../../helpers/getDisplayName';

function withApollo<T>(PageComponent: NextPage) {
  const WithApollo = (props: T) => {
    return <PageComponent {...props} />;
  };

  // only being executed at server-side
  WithApollo.getInitialProps = async (ctx: any) => {
    // there is chance that we can only render apollo components
    // after some redux data has been fetched
    // so we run PageComponent.getInitialProps first
    let pageProps = {};
    if (PageComponent.getInitialProps) {
      pageProps = await PageComponent.getInitialProps(ctx);
    }
    return pageProps;
  };

  WithApollo.displayName = `WithApollo${getDisplayName(PageComponent)}`;

  return WithApollo;
}

export default withApollo;
