import { Greyscale, ScreenSize } from 'glints-aries';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  background-color: ${Greyscale.white};
  border-radius: 3px;
  overflow: hidden;
  transition: box-shadow 0.2s;
  min-height: 370px;
  @media (min-width: ${ScreenSize.desktopS}px) {
    &:hover {
      box-shadow: 0 0 20px 0 rgba(193, 193, 193, 0.5);
    }
  }
`;

export const Details = styled.div`
  display: flex;
  padding-top: 0;
  margin-top: 15px;
`;

export const Title = styled.div`
  font-size: 15px;
  margin-top: 20px;
  color: ${Greyscale.black};
  height: 70px;
`;

export const StartDate = styled.div`
  margin-right: 15px;
  text-align: right;
`;

export const Month = styled.div`
  font-size: 15px;
  color: #0baeec;
  line-height: 1.33;
  margin-bottom: 5px;
  text-transform: uppercase;
`;

export const Date = styled.div`
  font-weight: 500;
  font-size: 18px;
  color: ${Greyscale.black};
`;

export const Location = styled.div`
  font-size: 14px;
  color: ${Greyscale.grey};
`;

export const LocationAndTag = styled.div`
  svg {
    margin-right: 6px;
  }
`;

export const CategoryContainer = styled.div`
  .aries-tag {
    margin: 0 5px 5px 0;
    font-size: 12px;
    color: ${Greyscale.black};
    border: none;
    label {
      padding: 3px 12px;
    }
  }
`;

export const Divider = styled.div`
  width: 0.5px;
  height: 48px;
  border: solid 1px #e3e3e3;
  margin-right: 15px;
`;

export const ContentContainer = styled.div`
  padding: 15px;
`;
