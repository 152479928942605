import React from 'react';
import { Loading } from 'glints-aries';

import * as S from './CenteredSpinner.sc';

export const CenteredSpinner: React.FC<
  React.PropsWithChildren<Record<string, unknown>>
> = () => (
  <S.SpinnerContainer>
    <Loading />
  </S.SpinnerContainer>
);
