import { ScreenSize } from 'glints-aries';
import styled from 'styled-components';

interface HeaderProps {
  headerPositionOnDesktop: 'left' | 'right' | 'top';
}

export const SliderSection = styled.div<HeaderProps>`
  @media (min-width: ${ScreenSize.desktopS}px) {
    display: flex;
    ${({ headerPositionOnDesktop }) => {
      switch (headerPositionOnDesktop) {
        case 'top':
          return `flex-direction: column;
                  align-items: center;`;
        case 'right':
          return `flex-direction: row-reverse;`;
        case 'left':
        default:
          return `flex-direction: row;`;
      }
    }};
  }
`;

const headerWidth = 440;
const headerMargin = 90;
const headerTotalWidth = headerWidth + headerMargin;

export const Header = styled.div<HeaderProps>`
  margin-bottom: 60px;
  @media (min-width: ${ScreenSize.desktopS}px) {
    margin-bottom: 0;
    width: ${headerWidth}px;
    ${({ headerPositionOnDesktop }) => {
      switch (headerPositionOnDesktop) {
        case 'top':
          return `margin-bottom: 90px;
                  width: 490px;`;
        case 'right':
          return `margin-left: ${headerMargin}px;`;
        case 'left':
        default:
          return `margin-right: ${headerMargin}px;`;
      }
    }}
  }
`;

export const Cards = styled.div`
  width: 100%;
  @media (min-width: ${ScreenSize.desktopS}px) {
    width: calc(100% - ${headerTotalWidth}px);
  }
`;

export const FullWidthCards = styled.div`
  width: 100%;
`;

export const HeaderLinkButtonContainer = styled.div`
  display: none;

  @media (min-width: ${ScreenSize.desktopS}px) {
    display: block;
    margin-top: 60px;
  }
`;

export const MobileLinkButtonContainer = styled.div`
  margin-top: 60px;
  display: flex;
  justify-content: center;

  @media (min-width: ${ScreenSize.desktopS}px) {
    display: none;
  }
`;

export const VerticalSliderSectionLinkButtonContainer = styled.div`
  margin-top: 60px;
  display: flex;
  justify-content: center;
`;
