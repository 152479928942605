import * as React from 'react';
import { ArrowRoundForwardIcon } from 'glints-aries';
import Link from 'next/link';
import { defineMessages, useIntl } from 'react-intl';

import { GoogleTagManager } from 'src/components/GoogleTagManager';
import { EBreakpoints, Media } from 'src/media';
import ExpertClassCard from 'src/modules/Landing/Components/Layout/ExpertClassCards/ExpertClassCard';
import { HorizontalSliderSection } from 'src/modules/Landing/Components/Layout/HorizontalSliderSection';
import { LinkButton } from 'src/modules/Landing/Components/Layout/LinkButton.sc';
import { SectionHeader } from 'src/modules/Landing/Components/Layout/SectionHeader';
import { ExpertClass } from 'src/modules/Landing/interfaces';

const messages = defineMessages({
  title: {
    id: 'homepage-sectionexpertclass-title',
    defaultMessage: 'Learn Directly{linebreak}from experts',
  },
  subtitle: {
    id: 'homepage-sectionexpertclass-subtitle',
    defaultMessage:
      'Stay competitive. Learn from practitioners and improve your skills in digital marketing, design, coding, data, and more.',
  },
  link: {
    id: 'homepage-sectionexpertclass-ctatext',
    defaultMessage: 'Discover more interesting Expertclasses',
  },
});

const Title = () => {
  const intl = useIntl();
  return (
    <React.Fragment>
      <Media lessThan={EBreakpoints.desktopS}>
        {intl.formatMessage(messages.title, {
          linebreak: ' ',
        })}
      </Media>
      <Media greaterThanOrEqual={EBreakpoints.desktopS}>
        {intl.formatMessage(messages.title, {
          linebreak: <br key="br" />,
        })}
      </Media>
    </React.Fragment>
  );
};

const SectionExpertClass = ({
  expertClasses,
}: {
  expertClasses: ExpertClass[];
}) => {
  const intl = useIntl();

  return (
    <HorizontalSliderSection
      headerPositionOnDesktop="right"
      header={
        <SectionHeader
          title={<Title />}
          subtitle={intl.formatMessage(messages.subtitle)}
        />
      }
      slides={expertClasses.map((expertClass: ExpertClass, index: number) => (
        <GoogleTagManager
          tag="amplitude-community-landing_page-expert-class-card"
          key={index}
        >
          {(gtmClass: string) => (
            <ExpertClassCard expertClass={expertClass} className={gtmClass} />
          )}
        </GoogleTagManager>
      ))}
      linkButton={
        <Link href="/expert-class">
          <a>
            <GoogleTagManager tag="amplitude-community-landing_page-expert-class-link-button">
              <LinkButton variant="link">
                {intl.formatMessage(messages.link)}
                <ArrowRoundForwardIcon />
              </LinkButton>
            </GoogleTagManager>
          </a>
        </Link>
      }
    />
  );
};

export default React.memo(SectionExpertClass);
