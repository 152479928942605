import * as React from 'react';

import * as S from './HorizontalScrollContainer.sc';

const HorizontalScrollContainer = ({
  items,
  isWithDivider,
  onScrollListener,
}: {
  items: React.ReactNodeArray;
  isWithDivider?: boolean;
  onScrollListener?: () => void;
}) => {
  const StyledItem = isWithDivider ? S.DividerItem : S.Item;
  return (
    <S.Container onScroll={onScrollListener}>
      <S.InnerContainer>
        {items.map((item, index) => (
          <StyledItem key={index}>{item}</StyledItem>
        ))}
      </S.InnerContainer>
    </S.Container>
  );
};

export default React.memo(HorizontalScrollContainer);
