import { handleActions } from 'redux-actions';

import { Actions } from 'src/actions/expertClasses';
import { Event } from 'src/global/models';
import { DstAction } from 'src/middleware/api/interfaces';

export interface ExpertClassesState {
  loading: boolean;
  error: any;
  value: Event[];
}

export const initialState: ExpertClassesState = {
  loading: false,
  error: null,
  value: [],
};

export const ExpertClassesReducer = handleActions<ExpertClassesState, any>(
  {
    [Actions.EXPERT_CLASS_REQUEST]: (state) => ({
      ...state,
      loading: true,
      error: null,
      value: [],
    }),
    [Actions.EXPERT_CLASS_SUCCESS]: (state, action: DstAction) => ({
      ...state,
      loading: false,
      error: null,
      value: action.payload.data,
    }),
    [Actions.EXPERT_CLASS_FAILURE]: (state, action: DstAction) => ({
      ...state,
      loading: false,
      error: action.error,
      value: [],
    }),
  },
  initialState
);
