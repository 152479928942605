import * as React from 'react';

import * as S from './SectionHeader.sc';

interface Props {
  title: string | React.ReactNode;
  subtitle: string;
  textAlignOnDesktop?: 'center' | 'left';
}

export const SectionHeader = ({
  title,
  subtitle,
  textAlignOnDesktop = 'left',
}: Props) => (
  <S.Root textAlignOnDesktop={textAlignOnDesktop}>
    <S.Title>{title}</S.Title>
    <S.Divider />
    <S.Subtitle>{subtitle}</S.Subtitle>
  </S.Root>
);
