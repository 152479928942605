import * as React from 'react';
import { Greyscale, LocationIcon, Tag } from 'glints-aries';
import moment from 'moment';
import Link from 'next/link';
import { FormattedMessage } from 'react-intl';

import { getExpertClassLinkProps } from 'src/common/links';
import BannerImage from 'src/components/Cards/EventCard/BannerImage';
import { ExpertClass } from 'src/modules/Landing/interfaces';

import * as S from './ExpertClassCard.sc';

interface Props {
  expertClass: ExpertClass;
  className: string;
}

const ExpertClassCard = ({ expertClass, className }: Props) => {
  const {
    id,
    bannerPic,
    title,
    startDate,
    location,
    links,
    isOnline,
    shortId,
  } = expertClass;
  const date = moment(new Date(startDate));
  const month = date.format('MMM');
  const categoriesList = links.categories
    .map((category: { name: string }) => category.name)
    .slice(0, 2);
  const expertClassDetailPageLink = getExpertClassLinkProps({ title, shortId });

  return (
    <Link {...expertClassDetailPageLink}>
      <a className={className}>
        <S.Container>
          <BannerImage
            eventId={id}
            src={bannerPic}
            sizes="308px"
            breakpoints={308}
          />
          <S.ContentContainer>
            <S.Title>{title}</S.Title>
            <S.Details>
              <S.StartDate>
                <S.Month>{month}</S.Month>
                <S.Date>{date.format('DD')}</S.Date>
              </S.StartDate>
              <S.Divider />
              <S.LocationAndTag>
                <S.CategoryContainer>
                  {categoriesList.map((category: string) => (
                    <Tag key={category}>{category} </Tag>
                  ))}
                </S.CategoryContainer>
                <S.Location>
                  <LocationIcon color={Greyscale.grey} />

                  {isOnline ? (
                    <FormattedMessage
                      id="event-card.online"
                      defaultMessage="Online on zoom"
                    />
                  ) : (
                    location
                  )}
                </S.Location>
              </S.LocationAndTag>
            </S.Details>
          </S.ContentContainer>
        </S.Container>
      </a>
    </Link>
  );
};

export default ExpertClassCard;
