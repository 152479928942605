import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { CenteredSpinner } from 'src/components/Spinner/CenteredSpinner';
import { getIsBrowserPrevNextNavigation } from 'src/selectors/history';
import { getIsWebCrawler } from 'src/selectors/server';

/**
 * This component handles dynamic rendering:
 * it always renders its children when client is web crawler and
 * when browser's back and next events are detected for scroll position restoration.
 * Otherwise, it only renders its children when it's in view.
 */
export const InView: React.FC<
  React.PropsWithChildren<{
    isWithoutFallback?: boolean;
    fallback?: React.ReactNode;
  }>
> = ({ children, isWithoutFallback, fallback }) => {
  const isBrowserPrevNextNavigation = useSelector(
    getIsBrowserPrevNextNavigation
  );
  const isWebCrawler = useSelector(getIsWebCrawler);
  const [isInView, setIsInView] = useState(false);
  const ref = useRef();

  const shouldRenderChildren =
    isWebCrawler || isBrowserPrevNextNavigation || isInView;

  useEffect(() => {
    let observer: IntersectionObserver;

    if (!shouldRenderChildren) {
      const onIntersect: IntersectionObserverCallback = async (
        entries,
        observer
      ) => {
        const target = entries[0];
        if (target.isIntersecting) {
          observer.disconnect();
          setIsInView(true);
        }
      };

      observer = new IntersectionObserver(onIntersect, {
        rootMargin: '0px 0px 300px 0px',
      });

      observer.observe(ref.current);
    }

    return () => {
      return observer?.disconnect?.();
    };
  }, [shouldRenderChildren]);

  const fallbackComponent = isWithoutFallback
    ? null
    : fallback || <CenteredSpinner />;

  return shouldRenderChildren ? (
    <>{children}</>
  ) : (
    <div ref={ref}>{fallbackComponent}</div>
  );
};
