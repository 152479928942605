import { createAction } from 'redux-actions';

import { ReduxThunkAction } from 'src/global/store';

export const Actions = {
  EXPERT_CLASS_REQUEST: 'glints/expert_class/EXPERT_CLASS_REQUEST',
  EXPERT_CLASS_SUCCESS: 'glints/expert_class/EXPERT_CLASS_SUCCESS',
  EXPERT_CLASS_FAILURE: 'glints/expert_class/EXPERT_CLASS_FAILURE',
};

const fetchExpertClassesRequest = createAction(Actions.EXPERT_CLASS_REQUEST);
const fetchExpertClassesSuccess = createAction(Actions.EXPERT_CLASS_SUCCESS);
const fetchExpertClassesFailure = createAction(Actions.EXPERT_CLASS_FAILURE);

export function fetchExpertClasses(): ReduxThunkAction<any> {
  return async (dispatch, getState, { api }) => {
    dispatch(fetchExpertClassesRequest());
    try {
      const limit = 9;
      const response = await api().get('/expertClasses', {
        params: {
          limit,
          order: 'startDate DESC',
          include: 'Categories',
        },
      });
      dispatch(fetchExpertClassesSuccess(response.data));
    } catch (err) {
      dispatch(fetchExpertClassesFailure(err));
    }
  };
}
