import * as React from 'react';
import { Button } from 'glints-aries';
import Link from 'next/link';
import { defineMessages, useIntl } from 'react-intl';

import { GoogleTagManager } from 'src/components/GoogleTagManager';

import * as S from './SectionSignUpCTA.sc';

const messages = defineMessages({
  title: {
    id: 'homepage-sectionbottomcta-title-startbuilding',
    defaultMessage: 'Start building',
  },
  subtitle: {
    id: 'homepage-sectionbottomcta-title-careeryoulove',
    defaultMessage: 'A CAREER YOU LOVE',
  },
  signup: {
    id: 'homepage-sectionbottomcta-ctatext',
    defaultMessage: 'SIGN UP NOW',
  },
});

const SectionSignUpCTA = () => {
  const intl = useIntl();

  return (
    <S.Root>
      <S.Content>
        <S.Title>{intl.formatMessage(messages.title)}</S.Title>
        <S.Subtitle>{intl.formatMessage(messages.subtitle)}</S.Subtitle>
        <S.Button>
          <Link href="/signup" prefetch={false}>
            <a>
              <GoogleTagManager tag="amplitude-community-landing_page-bottom-sign-up-button">
                <Button variant="ghost">
                  {intl.formatMessage(messages.signup)}
                </Button>
              </GoogleTagManager>
            </a>
          </Link>
        </S.Button>
      </S.Content>
    </S.Root>
  );
};

export default React.memo(SectionSignUpCTA);
