import { Button } from 'glints-aries';
import styled from 'styled-components';

export const LinkButton = styled(Button)`
  display: block;
  font-size: 16px;
  font-weight: 600;
  svg {
    margin-left: 10px;
  }
`;
