import styled from 'styled-components';

export const Container = styled.div`
  .slider-item.slider-item {
    padding-top: 0;
  }
`;

export const Item = styled.div`
  margin: 0 5px;
  width: 310px;
  padding-bottom: 30px;
`;

export const LoadingItem = styled.div`
  margin: 0 5px;
  width: 310px;
  height: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
`;
