import * as React from 'react';
import { ScreenSize } from 'glints-aries';
import Link from 'next/link';
import { defineMessages, useIntl } from 'react-intl';

import GlintsPicture from 'src/components/GlintsPicture';
import { GoogleTagManager } from 'src/components/GoogleTagManager';

import * as S from './SectionOneStop.sc';
import { ApplyIcon, ClassIcon } from './SectionOneStopIcons';

const messages = defineMessages({
  title: {
    id: 'homepage-sectionproductoverview-title',
    defaultMessage: 'YOUR ONE-STOP CAREER DEVELOPMENT PLATFORM',
  },
  subtitle: {
    id: 'homepage-sectionproductoverview-subtitle',
    defaultMessage:
      'Make smarter career decisions and build a career you love! ',
  },
  ask: {
    id: 'homepage-sectionproductoverview-askcolumn-asktitle',
    defaultMessage: 'ASK',
  },
  askSubTitle: {
    id: 'homepage-sectionproductoverview-askcolumn-asksubtitle',
    defaultMessage: 'IN COMMUNITY',
  },
  learn: {
    id: 'homepage-sectionproductoverview-learncolumn-learntitle',
    defaultMessage: 'LEARN',
  },
  learnSubTitle: {
    id: 'homepage-sectionproductoverview-learncolumn-learnsubtitle',
    defaultMessage: 'FROM EXPERTS',
  },
  upskill: {
    id: 'homepage-sectionproductoverview-upskillcolumn-upskilltitle',
    defaultMessage: 'UPSKILL',
  },
  upskillSubTitle: {
    id: 'homepage-sectionproductoverview-upskillcolumn-upskillsubtitle',
    defaultMessage: 'THROUGH ACADEMY',
  },
  apply: {
    id: 'homepage-sectionproductoverview-applycolumn-applytitle',
    defaultMessage: 'APPLY',
  },
  applySubTitle: {
    id: 'homepage-sectionproductoverview-applycolumn-applysubtitle',
    defaultMessage: 'FOR YOUR DREAM JOB',
  },
});

const SectionOneStop = () => {
  const intl = useIntl();

  return (
    <S.Root>
      <S.Header>
        <S.Title>{intl.formatMessage(messages.title)}</S.Title>
        <S.Subtitle>{intl.formatMessage(messages.subtitle)}</S.Subtitle>
      </S.Header>
      <S.Cards>
        <Link href="/expert-class">
          <a>
            <GoogleTagManager tag="amplitude-community-landing_page-one-stop-section-learn">
              <S.Card>
                <S.CardImage>
                  <ClassIcon />
                </S.CardImage>
                <S.CardText>
                  <S.CardTitle>
                    {intl.formatMessage(messages.learn)}
                  </S.CardTitle>
                  <S.CardSubtitle>
                    {intl.formatMessage(messages.learnSubTitle)}
                  </S.CardSubtitle>
                </S.CardText>
              </S.Card>
            </GoogleTagManager>
          </a>
        </Link>
        <Link href="/lowongan-kerja" prefetch={false}>
          <a>
            <GoogleTagManager tag="amplitude-community-landing_page-one-stop-section-apply">
              <S.Card>
                <S.CardImage>
                  <ApplyIcon />
                </S.CardImage>
                <S.CardText>
                  <S.CardTitle>
                    {intl.formatMessage(messages.apply)}
                  </S.CardTitle>
                  <S.CardSubtitle>
                    {intl.formatMessage(messages.applySubTitle)}
                  </S.CardSubtitle>
                </S.CardText>
              </S.Card>
            </GoogleTagManager>
          </a>
        </Link>
      </S.Cards>
      <S.Image>
        <GlintsPicture
          name="landing/one-stop.png"
          sizes={`(min-width: ${ScreenSize.tablet}px) 808px, 100vw`}
        />
      </S.Image>
    </S.Root>
  );
};

export default React.memo(SectionOneStop);
