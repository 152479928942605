import { Greyscale, ScreenSize, SecondaryColor } from 'glints-aries';
import styled from 'styled-components';

export const Root = styled.div`
  margin-bottom: -90px;
  background-color: ${SecondaryColor.actionblue};
  padding: 60px 10px;
  text-align: center;
  color: ${Greyscale.white};
`;

export const Content = styled.div`
  display: inline-block;
  max-width: 300px;
  @media (min-width: ${ScreenSize.desktopS}px) {
    max-width: unset;
  }
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 500;
  line-height: normal;

  @media (min-width: ${ScreenSize.desktopS}px) {
    font-size: 40px;
  }
`;

export const Subtitle = styled.div`
  font-size: 28px;
  font-weight: bold;
  line-height: normal;
  margin-bottom: 30px;
  text-transform: uppercase;
  @media (min-width: ${ScreenSize.desktopS}px) {
    font-size: 44px;
  }
`;

export const Button = styled.div`
  margin: 0 auto;
  max-width: 340px;
  font-size: 16px;

  a {
    display: block;
  }
  .aries-ghostbtn,
  .ghostbtn-content {
    width: 100%;
  }
`;
