import * as React from 'react';

import { EBreakpoints, Media } from 'src/media';
import HorizontalScrollContainer from 'src/modules/Landing/Components/Layout/HorizontalScrollContainer/HorizontalScrollContainer';
import OneCardSlider from 'src/modules/Landing/Components/Layout/OneCardSlider/OneCardSlider';
import TwoCardsSlider from 'src/modules/Landing/Components/Layout/TwoCardsSlider/TwoCardsSlider';

import * as S from './SliderSection.sc';

interface Props {
  slides: React.ReactNodeArray;
  header: React.ReactNode;
  linkButton: React.ReactNode;
  headerPositionOnDesktop: 'left' | 'right';
}

export const HorizontalSliderSection = ({
  slides,
  header,
  linkButton,
  headerPositionOnDesktop,
}: Props) => {
  return (
    <S.SliderSection headerPositionOnDesktop={headerPositionOnDesktop}>
      <S.Header headerPositionOnDesktop={headerPositionOnDesktop}>
        {header}
        <Media greaterThan={EBreakpoints.tablet}>
          <S.HeaderLinkButtonContainer>
            {linkButton}
          </S.HeaderLinkButtonContainer>
        </Media>
      </S.Header>
      <S.Cards>
        <Media lessThan={EBreakpoints.desktopS}>
          <HorizontalScrollContainer items={slides} />
          <S.MobileLinkButtonContainer>
            {linkButton}
          </S.MobileLinkButtonContainer>
        </Media>
        <Media at={EBreakpoints.desktopS}>
          <OneCardSlider slides={slides} />
        </Media>
        <Media at={EBreakpoints.desktopM}>
          <OneCardSlider slides={slides} />
        </Media>
        <Media greaterThanOrEqual={EBreakpoints.desktopL}>
          <TwoCardsSlider slides={slides} />
        </Media>
      </S.Cards>
    </S.SliderSection>
  );
};
