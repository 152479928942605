import { defineMessages } from 'react-intl';

import { LandingCounterDescriptionItem, PressOutletItem } from '../interfaces';

export const CareerGridCategoryPositions = [
  {
    hierarchicalJobCategoryId: '6202933c-ad93-45ac-86a9-b3e61042bc7c',
    position: {
      columnStart: 1,
      columnEnd: 2,
      rowStart: 1,
      rowEnd: 3,
    },
    backgroundImage: 'business-development.jpg',
  },
  {
    backgroundImage: 'finance.jpg',
    position: {
      columnStart: 2,
      columnEnd: 3,
      rowStart: 1,
      rowEnd: 2,
    },
    hierarchicalJobCategoryId: '821d6ce2-9522-4e4f-adb1-2a0ee2fb5e43',
  },
  {
    backgroundImage: 'product-management.jpg',
    hierarchicalJobCategoryId: '4857ee36-2866-4ffd-b32f-5d780f0cc19b',
    position: {
      columnStart: 3,
      columnEnd: 5,
      rowStart: 1,
      rowEnd: 2,
    },
  },
  {
    position: {
      columnStart: 5,
      columnEnd: 6,
      rowStart: 1,
      rowEnd: 3,
    },
    hierarchicalJobCategoryId: 'f1efc1a5-79e7-48e2-8adc-50dd141fe6e9',
    backgroundImage: 'human-resource.jpg',
  },
  {
    backgroundImage: 'management.jpg',
    hierarchicalJobCategoryId: '3cc5be5e-2f69-4dfa-89f8-a9bb889ff786',
    position: {
      columnStart: 2,
      columnEnd: 3,
      rowStart: 2,
      rowEnd: 3,
    },
  },
  {
    backgroundImage: 'engineering.jpg',
    hierarchicalJobCategoryId: '9dd9bc4d-38d5-4b5e-876a-de19bc22da45',
    position: {
      columnStart: 3,
      columnEnd: 4,
      rowStart: 2,
      rowEnd: 4,
    },
  },
  {
    backgroundImage: 'design.jpg',
    hierarchicalJobCategoryId: '0a9f9b0d-d2b1-44f0-8851-81129cf49970',
    position: {
      columnStart: 4,
      columnEnd: 5,
      rowStart: 2,
      rowEnd: 3,
    },
  },
  {
    backgroundImage: 'operations.jpg',
    hierarchicalJobCategoryId: '376816e0-f3e2-48c8-b14b-73467ccaa0f8',
    position: {
      columnStart: 1,
      columnEnd: 3,
      rowStart: 3,
      rowEnd: 4,
    },
  },
  {
    backgroundImage: 'marketing.jpg',
    hierarchicalJobCategoryId: 'f30ca820-580e-4e07-bf23-1f8fb96d6fc4',
    position: {
      columnStart: 4,
      columnEnd: 5,
      rowStart: 3,
      rowEnd: 4,
    },
  },
  {
    backgroundImage: 'media-and-communications.jpg',
    hierarchicalJobCategoryId: '032fe1c6-767d-45c1-97e4-9cb502055e4a',
    position: {
      columnStart: 5,
      columnEnd: 6,
      rowStart: 3,
      rowEnd: 4,
    },
  },
] as const;

export const SearchChoices = [
  { label: 'Jobs', value: 'FULL_TIME', i18n: 'landing.search.choice.jobs' },
  {
    label: 'Internships',
    value: 'INTERNSHIP',
    i18n: 'landing.search.choice.internships',
  },
];

export const PressOutlets: PressOutletItem[] = [
  { logo: 'cna', name: 'Channel NewsAsia', width: 50, height: 45 },
  { logo: 'huffington', name: 'The Huffington Post', width: 293, height: 35 },
  { logo: 'yahoo', name: 'Yahoo News', width: 104, height: 45 },
  { logo: 'straitstimes', name: 'Channel NewsAsia', width: 63, height: 45 },
  { logo: 'techcrunch', name: 'TechCrunch', width: 181, height: 45 },
  { logo: 'business-times', name: 'Business Times', width: 293, height: 28 },
];

const statisticsOptions = {
  PREVIOUS_MONTH: 'previousMonth',
  TOTAL: 'total',
};

const captionMessages = defineMessages({
  users: {
    id: 'we.have.helped',
    defaultMessage: "We've helped",
  },
  companies: {
    id: 'We.have.empowered',
    defaultMessage: "We've empowered",
  },
  jobs: {
    id: 'Explore',
    defaultMessage: 'Explore',
  },
});

const descriptionMessages = defineMessages({
  users: {
    id: 'young.professionals.to.discover.their',
    defaultMessage: 'Young Professionals To Discover Their Dream Careers',
  },
  companies: {
    id: 'companies.worldwide.to.build.successful',
    defaultMessage: 'Companies Worldwide, To Build Successful Teams',
  },
  jobs: {
    id: 'hot.opportunities.posted.monthly',
    defaultMessage: 'Hot Opportunities Posted Monthly',
  },
});

export const LandingCounterDescription: LandingCounterDescriptionItem[] = [
  {
    name: 'users',
    captionMessage: captionMessages.users,
    descriptionMessage: descriptionMessages.users,
    displayedStatistic: statisticsOptions.TOTAL,
  },
  {
    name: 'companies',
    captionMessage: captionMessages.companies,
    descriptionMessage: descriptionMessages.companies,
    displayedStatistic: statisticsOptions.TOTAL,
  },
  {
    name: 'jobs',
    captionMessage: captionMessages.jobs,
    descriptionMessage: descriptionMessages.jobs,
    displayedStatistic: statisticsOptions.PREVIOUS_MONTH,
  },
];
