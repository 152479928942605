import { ScreenSize } from 'glints-aries';
import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 -10px;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  @media (min-width: ${ScreenSize.mobileM}px) {
    margin: 0 -20px;
  }
  @media (min-width: ${ScreenSize.mobileL}px) {
    margin: 0 -24px;
  }
`;

export const InnerContainer = styled.div`
  display: inline-flex;
  padding: 0 10px;
  @media (min-width: ${ScreenSize.mobileM}px) {
    padding: 0 15px;
  }
  @media (min-width: ${ScreenSize.mobileL}px) {
    padding: 0 19px;
  }
`;

export const Item = styled.div`
  width: calc(100vw - 30px);
  max-width: 310px;
  margin: 0 5px;
  @media (min-width: ${ScreenSize.mobileM}px) {
    width: 310px;
  }
  @media (min-width: ${ScreenSize.desktopS}px) {
    padding-bottom: 30px;
  }
`;

export const DividerItem = styled.div`
  &:not(:last-child) {
    border-right: 2px solid #e3e3e3;
  }
  width: calc(100vw - 30px);
  max-width: 310px;
  padding: 0 5px;
  margin-right: 10px;
  @media (min-width: ${ScreenSize.mobileM}px) {
    width: 310px;
    padding: 0 15px;
    margin-right: 0px;
  }
`;
