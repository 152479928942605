import get from 'lodash/get';
import { createAction } from 'redux-actions';

import { ReduxThunkAction } from 'src/global/store';

export const Actions = {
  SET_LANDING_COUNTER_NUMBERS:
    'glints/landing_psych_flat/SET_LANDING_COUNTER_NUMBERS',
  SET_OPPORTUNITIES_COUNT: 'glints/landing_psych_flat/SET_OPPORTUNITIES_COUNT',
};

export const setLandingCounterNumbers = createAction(
  Actions.SET_LANDING_COUNTER_NUMBERS
);
export const setOpportunitiesCount = createAction(
  Actions.SET_OPPORTUNITIES_COUNT
);

export function fetchLandingCounterNumbers(): ReduxThunkAction<any> {
  return async (dispatch, getState, { api }) => {
    try {
      const response: any = await api().get('/stats/landing');
      if (!response.isAxiosError) {
        dispatch(setLandingCounterNumbers(response.data));
      }
    } catch (err) {
      const error = get(
        err,
        'response.data',
        'The error occurred in the /stats/landing endpoint'
      );
      console.warn(error);
    }
  };
}
