import * as React from 'react';
import { Loading, Slider } from 'glints-aries';

import * as S from './OneCardSlider.sc';

const OneCardSlider = ({ slides }: { slides: React.ReactNodeArray }) => (
  <S.Container>
    {slides.length ? (
      <Slider key="loaded">
        {slides.map((slide, index) => (
          <Slider.Item key={index}>
            <S.Item>{slide}</S.Item>
          </Slider.Item>
        ))}
      </Slider>
    ) : (
      <Slider key="loading">
        <Slider.Item>
          <S.LoadingItem>
            <Loading />
          </S.LoadingItem>
        </Slider.Item>
      </Slider>
    )}
  </S.Container>
);

export default React.memo(OneCardSlider);
