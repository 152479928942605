import { State } from 'src/global/store';

const getHistory = (state: State) => state.history;

export const getIsBrowserPrevNextNavigation = (state: State) =>
  getHistory(state).isBrowserPrevNextNavigation;

export const getPreviousAsPath = (state: State) => {
  const asPaths = getHistory(state).asPaths;
  return asPaths.length > 1 ? asPaths[asPaths.length - 2] : '';
};

export const getInitialAsPath = (state: State) => {
  const asPaths = getHistory(state).asPaths;

  return asPaths[0];
};
