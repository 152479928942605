import { handleActions } from 'redux-actions';

import { Actions } from './Actions';
import { LandingCounterNumbers, OpportunitiesCount } from './interfaces';

export interface ReducerState {
  opportunitiesCount: OpportunitiesCount;
  landingCounterNumbers: LandingCounterNumbers;
}

export const initialState: ReducerState = {
  opportunitiesCount: {},
  landingCounterNumbers: {},
};

export const landingPsychFlatReducer = handleActions<ReducerState, any>(
  {
    [Actions.SET_LANDING_COUNTER_NUMBERS]: (state, action) => ({
      ...state,
      landingCounterNumbers: action.payload,
    }),
    [Actions.SET_OPPORTUNITIES_COUNT]: (state, action) => ({
      ...state,
      opportunitiesCount: action.payload,
    }),
  },
  initialState
);
