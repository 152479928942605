import { Greyscale, ScreenSize } from 'glints-aries';
import dynamic from 'next/dynamic';
import styled, { css } from 'styled-components';

import GlintsContainer from 'src/components/GlintsContainer';

const LoadableValueProposition = dynamic(
  () =>
    import(
      /* webpackChunkName: "LandingComponentsValueProposition" */ '../Components/ValueProposition'
    ),
  { ssr: false }
);

const LoadablePressFeatures = dynamic(
  () => import('../Components/PressFeatures'),
  { ssr: false }
);

const LoadableTestimonials = dynamic(
  () => import('../Components/Testimonials'),
  { ssr: false }
);

interface LightBlueBackgroundProps {
  showSignUpCta: boolean;
}

const stripe = css`
  padding-top: ${36 / 14}em;
  padding-bottom: ${36 / 14}em;

  @media only screen and (max-width: 767px) {
    padding-top: ${24 / 14}em;
    padding-bottom: ${24 / 14}em;
  }
`;

const whiteBg = css`
  background: ${Greyscale.white};
  text-align: center;
`;

export const ValueProposition = styled(LoadableValueProposition)`
  ${stripe}
  ${whiteBg}
`;

export const PressFeatures = styled(LoadablePressFeatures)`
  ${stripe}
  ${whiteBg}
`;

export const Testimonials = styled(LoadableTestimonials)`
  ${stripe}
  background: #2185d0;
  color: ${Greyscale.white};
`;

export const LandingBackground = styled.div<LightBlueBackgroundProps>`
  background: #fff;
  margin-bottom: ${({ showSignUpCta }) => (showSignUpCta ? '0' : '-90px')};
`;

export const Container = styled(GlintsContainer)`
  padding-right: 70px;
  padding-left: 70px;

  @media (max-width: ${ScreenSize.tablet}px) {
    padding-right: 25px;
    padding-left: 25px;
  }
`;

export const LandingBackgroundSectionContainer = styled.div`
  border-bottom: solid 1px #d7d7d7;
  padding: 60px 0;
  &:first-child,
  &:last-child {
    border-bottom: none;
  }
  &:first-child {
    padding-bottom: 0;
  }
  &:last-child {
    background-color: #f2f8fa;
  }
  @media (max-width: ${ScreenSize.mobileL}px) {
    padding: 40px 0;
  }
`;
